// extracted by css-extract-rspack-plugin
export default {"mobile-top-btns":"src-react-MobileTopButtons-module__mobile-top-btns-LkVyKp","mobileTopBtns":"src-react-MobileTopButtons-module__mobile-top-btns-LkVyKp","pause-btn":"src-react-MobileTopButtons-module__pause-btn-tfjBzl","pauseBtn":"src-react-MobileTopButtons-module__pause-btn-tfjBzl","chat-btn":"src-react-MobileTopButtons-module__chat-btn-BNyPws","chatBtn":"src-react-MobileTopButtons-module__chat-btn-BNyPws","debug-btn":"src-react-MobileTopButtons-module__debug-btn-aSsISO","debugBtn":"src-react-MobileTopButtons-module__debug-btn-aSsISO"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"mobile-top-btns\":\"src-react-MobileTopButtons-module__mobile-top-btns-LkVyKp\",\"mobileTopBtns\":\"src-react-MobileTopButtons-module__mobile-top-btns-LkVyKp\",\"pause-btn\":\"src-react-MobileTopButtons-module__pause-btn-tfjBzl\",\"pauseBtn\":\"src-react-MobileTopButtons-module__pause-btn-tfjBzl\",\"chat-btn\":\"src-react-MobileTopButtons-module__chat-btn-BNyPws\",\"chatBtn\":\"src-react-MobileTopButtons-module__chat-btn-BNyPws\",\"debug-btn\":\"src-react-MobileTopButtons-module__debug-btn-aSsISO\",\"debugBtn\":\"src-react-MobileTopButtons-module__debug-btn-aSsISO\"}";
        // 1726713646075
        var cssReload = require("../../node_modules/.pnpm/@rspack+core@1.0.5_@swc+helpers@0.5.13/node_modules/@rspack/core/dist/builtin-plugin/css-extract/hmr/hotModuleReplacement.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  