// extracted by css-extract-rspack-plugin
export default {"root":"src-react-mainMenu-module__root-ZQcBnP","game-title":"src-react-mainMenu-module__game-title-HS2Boh","gameTitle":"src-react-mainMenu-module__game-title-HS2Boh","minec":"src-react-mainMenu-module__minec-LtvUe5","minecraft":"src-react-mainMenu-module__minecraft-WAwwet","raft":"src-react-mainMenu-module__raft-pGttvN","edition":"src-react-mainMenu-module__edition-X71qt9","splash":"src-react-mainMenu-module__splash-T86ZOq","splashAnim":"src-react-mainMenu-module__splashAnim-hJpV1p","menu":"src-react-mainMenu-module__menu-AViyuB","menu-row":"src-react-mainMenu-module__menu-row-Ua3i92","menuRow":"src-react-mainMenu-module__menu-row-Ua3i92","bottom-info":"src-react-mainMenu-module__bottom-info-XUJ4_b","bottomInfo":"src-react-mainMenu-module__bottom-info-XUJ4_b","product-info":"src-react-mainMenu-module__product-info-NVuoOG","productInfo":"src-react-mainMenu-module__product-info-NVuoOG","product-description":"src-react-mainMenu-module__product-description-mpFNW4","productDescription":"src-react-mainMenu-module__product-description-mpFNW4","product-link":"src-react-mainMenu-module__product-link-iL2GHi","productLink":"src-react-mainMenu-module__product-link-iL2GHi","maps-provider":"src-react-mainMenu-module__maps-provider-JGaNCh","mapsProvider":"src-react-mainMenu-module__maps-provider-JGaNCh"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"root\":\"src-react-mainMenu-module__root-ZQcBnP\",\"game-title\":\"src-react-mainMenu-module__game-title-HS2Boh\",\"gameTitle\":\"src-react-mainMenu-module__game-title-HS2Boh\",\"minec\":\"src-react-mainMenu-module__minec-LtvUe5\",\"minecraft\":\"src-react-mainMenu-module__minecraft-WAwwet\",\"raft\":\"src-react-mainMenu-module__raft-pGttvN\",\"edition\":\"src-react-mainMenu-module__edition-X71qt9\",\"splash\":\"src-react-mainMenu-module__splash-T86ZOq\",\"splashAnim\":\"src-react-mainMenu-module__splashAnim-hJpV1p\",\"menu\":\"src-react-mainMenu-module__menu-AViyuB\",\"menu-row\":\"src-react-mainMenu-module__menu-row-Ua3i92\",\"menuRow\":\"src-react-mainMenu-module__menu-row-Ua3i92\",\"bottom-info\":\"src-react-mainMenu-module__bottom-info-XUJ4_b\",\"bottomInfo\":\"src-react-mainMenu-module__bottom-info-XUJ4_b\",\"product-info\":\"src-react-mainMenu-module__product-info-NVuoOG\",\"productInfo\":\"src-react-mainMenu-module__product-info-NVuoOG\",\"product-description\":\"src-react-mainMenu-module__product-description-mpFNW4\",\"productDescription\":\"src-react-mainMenu-module__product-description-mpFNW4\",\"product-link\":\"src-react-mainMenu-module__product-link-iL2GHi\",\"productLink\":\"src-react-mainMenu-module__product-link-iL2GHi\",\"maps-provider\":\"src-react-mainMenu-module__maps-provider-JGaNCh\",\"mapsProvider\":\"src-react-mainMenu-module__maps-provider-JGaNCh\"}";
        // 1726713645858
        var cssReload = require("../../node_modules/.pnpm/@rspack+core@1.0.5_@swc+helpers@0.5.13/node_modules/@rspack/core/dist/builtin-plugin/css-extract/hmr/hotModuleReplacement.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  