// extracted by css-extract-rspack-plugin
export default {"container":"src-react-select-module__container-Y5gFld","input":"src-react-select-module__input-Vt4rMm"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"src-react-select-module__container-Y5gFld\",\"input\":\"src-react-select-module__input-Vt4rMm\"}";
        // 1726713646178
        var cssReload = require("../../node_modules/.pnpm/@rspack+core@1.0.5_@swc+helpers@0.5.13/node_modules/@rspack/core/dist/builtin-plugin/css-extract/hmr/hotModuleReplacement.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  