// extracted by css-extract-rspack-plugin
export default {"slider-container":"src-react-slider-module__slider-container-k26jGw","sliderContainer":"src-react-slider-module__slider-container-k26jGw","slider-thumb":"src-react-slider-module__slider-thumb-kWeJYr","sliderThumb":"src-react-slider-module__slider-thumb-kWeJYr","slider":"src-react-slider-module__slider-pQg1Tc","disabled":"src-react-slider-module__disabled-vWN9n8","label":"src-react-slider-module__label-My4K1P"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"slider-container\":\"src-react-slider-module__slider-container-k26jGw\",\"sliderContainer\":\"src-react-slider-module__slider-container-k26jGw\",\"slider-thumb\":\"src-react-slider-module__slider-thumb-kWeJYr\",\"sliderThumb\":\"src-react-slider-module__slider-thumb-kWeJYr\",\"slider\":\"src-react-slider-module__slider-pQg1Tc\",\"disabled\":\"src-react-slider-module__disabled-vWN9n8\",\"label\":\"src-react-slider-module__label-My4K1P\"}";
        // 1726713646289
        var cssReload = require("../../node_modules/.pnpm/@rspack+core@1.0.5_@swc+helpers@0.5.13/node_modules/@rspack/core/dist/builtin-plugin/css-extract/hmr/hotModuleReplacement.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  