// extracted by css-extract-rspack-plugin
export default {"debug-left-side":"src-react-DebugOverlay-module__debug-left-side-L_D95v","debugLeftSide":"src-react-DebugOverlay-module__debug-left-side-L_D95v","debug-right-side":"src-react-DebugOverlay-module__debug-right-side-mFhvHG","debugRightSide":"src-react-DebugOverlay-module__debug-right-side-mFhvHG","empty":"src-react-DebugOverlay-module__empty-x7G63G"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"debug-left-side\":\"src-react-DebugOverlay-module__debug-left-side-L_D95v\",\"debugLeftSide\":\"src-react-DebugOverlay-module__debug-left-side-L_D95v\",\"debug-right-side\":\"src-react-DebugOverlay-module__debug-right-side-mFhvHG\",\"debugRightSide\":\"src-react-DebugOverlay-module__debug-right-side-mFhvHG\",\"empty\":\"src-react-DebugOverlay-module__empty-x7G63G\"}";
        // 1726713645036
        var cssReload = require("../../node_modules/.pnpm/@rspack+core@1.0.5_@swc+helpers@0.5.13/node_modules/@rspack/core/dist/builtin-plugin/css-extract/hmr/hotModuleReplacement.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  