// extracted by css-extract-rspack-plugin
export default {"root":"src-react-singleplayer-module__root-HBZyzP","content":"src-react-singleplayer-module__content-C05XsQ","content_loading":"src-react-singleplayer-module__content_loading-whQTDO","contentLoading":"src-react-singleplayer-module__content_loading-whQTDO","world_root":"src-react-singleplayer-module__world_root-HUO6kD","worldRoot":"src-react-singleplayer-module__world_root-HUO6kD","world_title":"src-react-singleplayer-module__world_title-ZeGEbR","worldTitle":"src-react-singleplayer-module__world_title-ZeGEbR","world_title_right":"src-react-singleplayer-module__world_title_right-_QaLaq","worldTitleRight":"src-react-singleplayer-module__world_title_right-_QaLaq","world_info":"src-react-singleplayer-module__world_info-aesWwe","worldInfo":"src-react-singleplayer-module__world_info-aesWwe","world_info_formatted":"src-react-singleplayer-module__world_info_formatted-T9yz4t","worldInfoFormatted":"src-react-singleplayer-module__world_info_formatted-T9yz4t","world_info_description_line":"src-react-singleplayer-module__world_info_description_line-m_UAFX","worldInfoDescriptionLine":"src-react-singleplayer-module__world_info_description_line-m_UAFX","world_image":"src-react-singleplayer-module__world_image-RCHVi3","worldImage":"src-react-singleplayer-module__world_image-RCHVi3","image_missing":"src-react-singleplayer-module__image_missing-kzcgld","imageMissing":"src-react-singleplayer-module__image_missing-kzcgld","world_focused":"src-react-singleplayer-module__world_focused-KhkWMT","worldFocused":"src-react-singleplayer-module__world_focused-KhkWMT","title":"src-react-singleplayer-module__title-zNQMwq"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"root\":\"src-react-singleplayer-module__root-HBZyzP\",\"content\":\"src-react-singleplayer-module__content-C05XsQ\",\"content_loading\":\"src-react-singleplayer-module__content_loading-whQTDO\",\"contentLoading\":\"src-react-singleplayer-module__content_loading-whQTDO\",\"world_root\":\"src-react-singleplayer-module__world_root-HUO6kD\",\"worldRoot\":\"src-react-singleplayer-module__world_root-HUO6kD\",\"world_title\":\"src-react-singleplayer-module__world_title-ZeGEbR\",\"worldTitle\":\"src-react-singleplayer-module__world_title-ZeGEbR\",\"world_title_right\":\"src-react-singleplayer-module__world_title_right-_QaLaq\",\"worldTitleRight\":\"src-react-singleplayer-module__world_title_right-_QaLaq\",\"world_info\":\"src-react-singleplayer-module__world_info-aesWwe\",\"worldInfo\":\"src-react-singleplayer-module__world_info-aesWwe\",\"world_info_formatted\":\"src-react-singleplayer-module__world_info_formatted-T9yz4t\",\"worldInfoFormatted\":\"src-react-singleplayer-module__world_info_formatted-T9yz4t\",\"world_info_description_line\":\"src-react-singleplayer-module__world_info_description_line-m_UAFX\",\"worldInfoDescriptionLine\":\"src-react-singleplayer-module__world_info_description_line-m_UAFX\",\"world_image\":\"src-react-singleplayer-module__world_image-RCHVi3\",\"worldImage\":\"src-react-singleplayer-module__world_image-RCHVi3\",\"image_missing\":\"src-react-singleplayer-module__image_missing-kzcgld\",\"imageMissing\":\"src-react-singleplayer-module__image_missing-kzcgld\",\"world_focused\":\"src-react-singleplayer-module__world_focused-KhkWMT\",\"worldFocused\":\"src-react-singleplayer-module__world_focused-KhkWMT\",\"title\":\"src-react-singleplayer-module__title-zNQMwq\"}";
        // 1726713646103
        var cssReload = require("../../node_modules/.pnpm/@rspack+core@1.0.5_@swc+helpers@0.5.13/node_modules/@rspack/core/dist/builtin-plugin/css-extract/hmr/hotModuleReplacement.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  