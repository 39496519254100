// extracted by css-extract-rspack-plugin
export default {"container":"src-react-KeybindingsScreen-module__container-dRiZZT","group":"src-react-KeybindingsScreen-module__group-haorh2","group-category":"src-react-KeybindingsScreen-module__group-category-HNSIFU","groupCategory":"src-react-KeybindingsScreen-module__group-category-HNSIFU","actionBinds":"src-react-KeybindingsScreen-module__actionBinds-MbRkMv","warning-container":"src-react-KeybindingsScreen-module__warning-container-mgiK4D","warningContainer":"src-react-KeybindingsScreen-module__warning-container-mgiK4D","actionName":"src-react-KeybindingsScreen-module__actionName-uFJWW2","undo-keyboard":"src-react-KeybindingsScreen-module__undo-keyboard-fjpyGp","undoKeyboard":"src-react-KeybindingsScreen-module__undo-keyboard-fjpyGp","undo-gamepad":"src-react-KeybindingsScreen-module__undo-gamepad-ZNUg28","undoGamepad":"src-react-KeybindingsScreen-module__undo-gamepad-ZNUg28","button":"src-react-KeybindingsScreen-module__button-AF2rj4","margin-left":"src-react-KeybindingsScreen-module__margin-left-ugrmlI","marginLeft":"src-react-KeybindingsScreen-module__margin-left-ugrmlI","matched-bind":"src-react-KeybindingsScreen-module__matched-bind-GFWOr1","matchedBind":"src-react-KeybindingsScreen-module__matched-bind-GFWOr1","matched-bind-warning":"src-react-KeybindingsScreen-module__matched-bind-warning-WdXyU1","matchedBindWarning":"src-react-KeybindingsScreen-module__matched-bind-warning-WdXyU1","chat-command":"src-react-KeybindingsScreen-module__chat-command-onMok2","chatCommand":"src-react-KeybindingsScreen-module__chat-command-onMok2"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"src-react-KeybindingsScreen-module__container-dRiZZT\",\"group\":\"src-react-KeybindingsScreen-module__group-haorh2\",\"group-category\":\"src-react-KeybindingsScreen-module__group-category-HNSIFU\",\"groupCategory\":\"src-react-KeybindingsScreen-module__group-category-HNSIFU\",\"actionBinds\":\"src-react-KeybindingsScreen-module__actionBinds-MbRkMv\",\"warning-container\":\"src-react-KeybindingsScreen-module__warning-container-mgiK4D\",\"warningContainer\":\"src-react-KeybindingsScreen-module__warning-container-mgiK4D\",\"actionName\":\"src-react-KeybindingsScreen-module__actionName-uFJWW2\",\"undo-keyboard\":\"src-react-KeybindingsScreen-module__undo-keyboard-fjpyGp\",\"undoKeyboard\":\"src-react-KeybindingsScreen-module__undo-keyboard-fjpyGp\",\"undo-gamepad\":\"src-react-KeybindingsScreen-module__undo-gamepad-ZNUg28\",\"undoGamepad\":\"src-react-KeybindingsScreen-module__undo-gamepad-ZNUg28\",\"button\":\"src-react-KeybindingsScreen-module__button-AF2rj4\",\"margin-left\":\"src-react-KeybindingsScreen-module__margin-left-ugrmlI\",\"marginLeft\":\"src-react-KeybindingsScreen-module__margin-left-ugrmlI\",\"matched-bind\":\"src-react-KeybindingsScreen-module__matched-bind-GFWOr1\",\"matchedBind\":\"src-react-KeybindingsScreen-module__matched-bind-GFWOr1\",\"matched-bind-warning\":\"src-react-KeybindingsScreen-module__matched-bind-warning-WdXyU1\",\"matchedBindWarning\":\"src-react-KeybindingsScreen-module__matched-bind-warning-WdXyU1\",\"chat-command\":\"src-react-KeybindingsScreen-module__chat-command-onMok2\",\"chatCommand\":\"src-react-KeybindingsScreen-module__chat-command-onMok2\"}";
        // 1726713646171
        var cssReload = require("../../node_modules/.pnpm/@rspack+core@1.0.5_@swc+helpers@0.5.13/node_modules/@rspack/core/dist/builtin-plugin/css-extract/hmr/hotModuleReplacement.js").cssReload(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  